import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  heading: {
    fontFamily: "Poppins",
    fontWeight: 700,
    fontSize: "48px",
    lineHeight: "56px",
    textAlign: "center",
    color: "#FFFFFF",
    marginTop: "6rem",
    "@media (max-width: 600px)": {
      fontWeight: 700,
      fontSize: "32px",
      lineHeight: "46px",
    },
  },
  button1: {
    fontFamily: "Poppins",
    fontWeight: 600,
    fontSize: "16px",
  },
  buttonStyling: {
    display: "flex",
    justifyContent: "center",
    gap: "1rem",
  },
  firstgridStyling: {
    background: " rgba(255, 255, 255, 0.1)",
    border: "1px solid #F3347D",
    boxShadow: "inset 0px 4px 20px rgba(255, 255, 255, 0.1)",
    backdropFilter: "blur(20px)",
    padding: "2rem 1.4rem",
    borderRadius: "15px",
    "@media (max-width: 1200px)": {
      display: "flex",
      justifyContent: "center",
      flexDirection: "column",
      alignItems: "center",
    },
    "@media (max-width: 600px)": {
      display: "flex",
      justifyContent: "start",
      flexDirection: "column",
      alignItems: "start",
    },
  },
  secondgridStyling: {
    background: " rgba(255, 255, 255, 0.1)",
    border: " 1px solid #7733A1",
    boxShadow: "inset 0px 4px 20px rgba(255, 255, 255, 0.1)",
    backdropFilter: "blur(20px)",
    padding: "2rem 1.4rem",
    borderRadius: "15px",
    "@media (max-width: 1200px)": {
      display: "flex",
      justifyContent: "center",
      flexDirection: "column",
      alignItems: "center",
    },
    "@media (max-width: 600px)": {
      display: "flex",
      justifyContent: "start",
      flexDirection: "column",
      alignItems: "start",
    },
  },
  thirdgridStyling: {
    background: " rgba(255, 255, 255, 0.1)",
    border: " 1px solid #1E50FF",
    boxShadow: "inset 0px 4px 20px rgba(255, 255, 255, 0.1)",
    backdropFilter: "blur(20px)",
    padding: "2rem 1.4rem",
    borderRadius: "15px",
    "@media (max-width: 1200px)": {
      display: "flex",
      justifyContent: "center",
      flexDirection: "column",
      alignItems: "center",
    },
    "@media (max-width: 600px)": {
      display: "flex",
      justifyContent: "start",
      flexDirection: "column",
      alignItems: "start",
    },
  },
  boxHeading: {
    fontFamily: "Poppins",
    fontWeight: 700,
    fontSize: "28px",

    textTransform: "uppercase",

    color: "#7733A1",
  },
  boxHeading2: {
    fontFamily: "Poppins",
    fontWeight: 700,
    fontSize: "28px",
    display: "flex",
    alignItems: "center",
    textTransform: "uppercase",
    gap: "1rem",
    color: "#C138BB",
  },
  boxHeading3: {
    fontFamily: "Poppins",
    fontWeight: 700,
    fontSize: "28px",
    display: "flex",
    alignItems: "center",
    textTransform: "uppercase",
    gap: "1rem",
    color: "#E30077",
    "@media (max-width: 600px)": {
      fontWeight: 600,
      fontSize: "24px",
    },
  },
  shortBox: {
    fontFamily: "Poppins",
    fontWeight: 700,
    fontSize: "12px",
    backgroundColor: "rgba(243, 52, 125, 0.15)",
    color: "#F3347D",
    borderRadius: "55px",
    width: "53px",
    height: "22px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  subHeading: {
    fontFamily: "Poppins",
    fontWeight: 700,
    fontSize: "56px",
    color: "#fff",
    "@media (max-width: 600px)": {
      fontWeight: 600,
      fontSize: "46px",
    },
  },
  sub2Heading: {
    fontFamily: "Poppins",
    fontWeight: 500,
    fontSize: "18px",
    color: "#fff",
    "@media (max-width: 600px)": {
      fontWeight: 500,
      fontSize: "15px",
    },
  },
  listedText: {
    fontFamily: "Poppins",
    fontWeight: 400,
    fontSize: "16px",
    color: "#fff",
    "@media (max-width: 600px)": {
      fontWeight: 400,
      fontSize: "15px",
    },
  },
 
}));

export default useStyles;
