import { Box, Button, Container, Grid, useMediaQuery } from "@mui/material";
import React from "react";
import useStyles from "./styles";
import setting1 from "../../assets/setting1.png";
import setting2 from "../../assets/setting2.png";

import { PriceSection } from "../PriceSection/PriceSection";
export const BuildSection = () => {
  const classes = useStyles();
  const elevenmatches = useMediaQuery("(max-width:1100px)");
  const thousandmatches = useMediaQuery("(max-width:1000px)");

  const matches = useMediaQuery("(max-width:400px)");
  const sixmatches = useMediaQuery("(max-width:600px)");
  return (
    <>
      <Container
        maxWidth={sixmatches?"xs":thousandmatches || elevenmatches ? "sm" : "lg"}
      >
        <Box className={classes.heading}>#BUIDL with Web3indexer</Box>
        <Grid container spacing={0} className={classes.maingrid} my={"3rem"}>
          <Grid item xs={12} sm={12} md={12} lg={5.5}>
            <Box className={classes.boxstyling}>
              <Box>
                <img
                  src={setting1}
                  alt=""
                  srcset=""
                  width={matches ? "25%" : "auto"}
                />
              </Box>
              <Box className={classes.boxHeading}>Build Yourself!</Box>
              <Box className={classes.content}>
                Follow the Smart Money, see wherein price range are shifting to,
                pick out new tasks or tokens, and hint transactions right all
                the way down to the maximum granular level.
              </Box>
              <Box mb={{ xs: "1rem", md: "2rem" }}>
                <Button
                  variant="contained"
                  fullWidth
                  sx={{
                    background:
                      "linear-gradient(90deg, #5C1D94 0%, #7733A1 34.37%, #B12396 68.75%, #E30077 100%)",
                    paddingY: sixmatches ? "0.7rem" : "0.5rem",
                    borderRadius: "8px",

                    color: "#fff",
                    "&.MuiButton-root": {
                      textTransform: "none",
                    },
                    "&.MuiButton-root:hover": {
                      backgroundColor: "#1E50FF",
                    },
                  }}
                >
                  Start Building
                </Button>
              </Box>
            </Box>
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={5.5}>
            <Box className={classes.boxstyling}>
              <Box>
                <img
                  src={setting2}
                  alt=""
                  srcset=""
                  width={matches ? "25%" : "auto"}
                />
              </Box>
              <Box className={classes.boxHeading}>We can build for you.</Box>
              <Box className={classes.content2}>
                Don't have developer bandwidth to build Parsers?Don't worry. We
                got you covered! Experts from WEB3INDEXER Network can understand
                your requirement and give their estimates for your custom APIs.
                Choose the best estimate and pay in $INDEXER for the work.
              </Box>
              <Box mb={{ xs: "1rem", md: "2rem" }}>
                <Button
                  variant="outlined"
                  fullWidth
                  sx={{
                    border: "1px solid #EBEBEB",
                    color: "#fff",
                    paddingY: sixmatches ? "0.7rem" : "0.5rem",
                    borderRadius: "8px",
                    "&.MuiButton-root": {
                      textTransform: "none",
                    },
                    "&.MuiButton-root:hover": {
                      border: "1px solid #EBEBEB",
                    },
                  }}
                >
                  Get Started
                </Button>
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Container>
      <PriceSection />
    </>
  );
};
