import { createTheme, responsiveFontSizes } from "@mui/material/styles";

let theme = createTheme({
  palette: {
    primary: {
      main: "#5C1D94",
      dark: "#7733A1",
      light: "#B12396",
      contrastText: "#E30077",
    },

    background: {
      default: "#061139",
      paper: "#061139",
    },
  },
});

theme.overrides = {
  MuiCssBaseline: {
    "@global": {
      body: {
        fontFamily: "Poppins",
      },
    },
  },
};

theme = responsiveFontSizes(theme);

export default theme;
