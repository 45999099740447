import "./App.css";
import HeroSection from "./components/HeroSection/HeroSection.jsx";
import { Community } from "./components/community/Community";
import Scroll from "./components/SmoothScroll";
import 'video-react/dist/video-react.css'; // import css

function App() {
  return (
    <>
      <Scroll />
      <HeroSection />
    </>
  );
}

export default App;
