import React from "react";
import Container from "@mui/material/Container";
import { Box, Button, useMediaQuery } from "@mui/material";
import useStyles from "./styles";
import forwardIcon from "../../assets/arrow.png";
import { Footer } from "../Footer/Footer";
export const NewsLetter = () => {
  const classes = useStyles();
  const xxsmatches = useMediaQuery("(max-width:600px)");
  const xsmatches = useMediaQuery("(max-width:500px)");
  const eightmatches = useMediaQuery("(max-width:800px)");
  return (
    <>
      <Container maxWidth="lg" sx={{ marginTop: "4rem" }}>
        <Box className={classes.boxstyling}>
          <Box className={classes.topText}>Newsletter</Box>
          <Box className={classes.heading}>Never miss an update.</Box>
          <Box className={classes.content}>
            Sign up to our newsletter and stay up to date on product features,
            {xxsmatches ? <></> : <br />}
            development updates and excitinzg new projects.
          </Box>
          <Box className={classes.inputbox}>
            <Box className={classes.inputStyling}>
              <input
                type="text"
                placeholder="Your Email"
                className={classes.input}
                style={{
                  color: "#fff",
                  "$::placeholder": {
                    color: "#fff",
                  },
                }}
              />
            </Box>
            {/* <Box> */}
            <Button
              variant="contained"
              // fullWidth={eightmatches ? true : false}
              sx={{
                borderRadius: "8px",
                color: "#EBEBEB",
                fontFamily: "Poppins",
                fontSize: "14px",
                fontWeight:600,
                background:
                  "linear-gradient(90deg, #5C1D94 0%, #7733A1 34.37%, #B12396 68.75%, #E30077 100%)",
                padding: xsmatches
                  ? "0.7rem 1rem"
                  : xxsmatches
                  ? "0.8rem 1.6rem"
                  : "0.8rem 2rem",
                "&.MuiButton-root": {
                  textTransform: "none",
                },
                "&.MuiButton-root:hover": {
                  backgroundColor: "#1E50FF",
                },
              }}
            >
              Subscribe{" "}
              <img
                src={forwardIcon}
                alt=""
                srcset=""
                style={{ marginLeft: "1rem" }}
              />
            </Button>
            {/* </Box> */}
          </Box>
        </Box>
      </Container>
      <Footer />
    </>
  );
};
