import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  responsiveImage: {
    display: "flex",
    justifyContent: "center",
  },
  smallheading: {
    fontFamily: "Poppins",
    fontWeight: 700,
    fontSize: "16px",
    textAlign: "center",
    color: "#C138BB",
  },
  heading: {
    fontWeight: 700,
    fontSize: "48px",
    lineHeight: "72px",
    color: "#fff",
    "@media (max-width: 800px)": {
      fontSize: "40px",
    },
    "@media (max-width: 600px)": {
      fontSize: "24px",
      lineHeight: "36px",
    },
    "@media (max-width: 400px)": {
      fontWeight: 600,
      lineHeight: "30px",
      fontSize: "20px",
    },
  },
  imgstyling: {
    width: "89%",
    "@media (max-width: 600px)": {
      width: "100%",
    },
  },
  walletData: {
    backgroundColor: "#101b42",
    border: "2px solid #1E50FF",
    boxShadow:
      " 0px 4px 15px rgba(0, 0, 0, 0.25), inset 0px 1px 30px 5px rgba(255, 255, 255, 0.2)",
    backdropFilter: "blur(40px)",

    borderRadius: "10px",
    "@media (max-width: 600px)": {
      backdropFilter: "none",
    },
  },
  titlewalletData: {
    fontFamily: "Poppins",
    fontWeight: 500,
    fontSize: "18px",
    color: "rgba(255, 255, 255, 0.8)",
    display: "flex",
    justifyContent: "center",
  },
  dividerLine: {
    width: "100%",
    height: "0.1rem",
    backgroundColor: "rgba(255, 255, 255, 0.3)",
  },
  tripleImgeBox: {
    display: "flex",
    justifyContent: "center",
    gap: "0.5rem",
    marginTop: "1rem",
    "@media (max-width: 1200px)": {
      gap: "2rem",
    },
    "@media (max-width: 500px)": {
      justifyContent: "space-evenly",
      gap: "0rem",
    },
  },
  doubleImgeBox1: {
    display: "flex",
    justifyContent: "center",
    gap: "0.5rem",
    "@media (max-width: 1200px)": {
      gap: "2rem",
    },
    "@media (max-width: 500px)": {
      // justifyContent: "space-evenly",
      gap: "0.4rem",
    },
  },
  doubleImgeBox2: {
    display: "flex",
    justifyContent: "center",

    gap: "0.5rem",
    "@media (max-width: 1200px)": {
      gap: "2rem",
    },
    "@media (max-width: 500px)": {
      // justifyContent: "space-evenly",
      gap: "0.4rem",
    },
  },
  gridcontainer: {
    display: "flex",
    justifyContent: "space-between",
    "@media (max-width: 1200px)": {
      rowGap: "1rem",
    },
  },

  buttonfeatured: {
    background:
      "linear-gradient(#101b42 0 0) padding-box, linear-gradient(90deg, #5C1D94 0%, #7733A1 34.37%, #B12396 68.75%, #E30077 100%) border-box",
    backgroundColor: "rgba(255, 255, 255, 0.1)",

    border: " 3px solid transparent",
    borderRadius: "20px",
    boxShadow:
      "0px 4px 4px rgba(0, 0, 0, 0.25), inset 0px 4px 40px 8px rgba(255, 255, 255, 0.15)",
  },
  onetext: {
    fontFamily: "Poppins",
    fontWeight: 500,
    fontSize: "16px",
    lineHeight: "24px",
    color: " #D3D3D3",
    "@media (max-width: 900px)": {
      fontWeight: 400,
      fontSize: "14px",
      // lineHeight: "20px",
    },
    "@media (max-width: 800px)": {
      fontSize: "16px",
      fontWeight: 500,
    },
    "@media (max-width: 500px)": {
      fontSize: "14px",
      fontWeight: 500,
    },
    "@media (max-width: 400px)": {
      fontSize: "13px",
      fontWeight: 400,
    },
    "@media (max-width: 300px)": {
      fontSize: "12px",
      fontWeight: 400,
    },
  },
  twotext: {
    fontFamily: "Poppins",
    fontWeight: 300,
    fontSize: "12px",
    lineHeight: "18px",
    color: " #D3D3D3",
    textAlign: "end",
    "@media (max-width: 900px)": {
      fontWeight: 300,
      fontSize: "11px",
      lineHeight: "16px",
    },
    "@media (max-width: 800px)": {
      fontSize: "8px",
    },
    "@media (max-width: 600px)": {
      fontSize: "6px",
    },
    "@media (max-width: 300px)": {
      fontSize: "4px",
    },
  },
  textsBox: {
    display: "flex",
    justifyContent: "space-between",

    "@media (max-width: 900px)": {
      justifyContent: "space-between",
    },
    "@media (max-width: 800px)": {
      justifyContent: "space-around",
    },
    "@media (max-width: 600px)": {
      justifyContent: "space-around",
    },
  },
  outsideHeading: {
    fontFamily: "Poppins",
    fontWeight: 500,
    fontSize: "28px",
    lineHeight: "42px",
    color: "#FFFFFF",
    textAlign: "center",
    "@media (max-width: 400px)": {
      fontWeight: 400,
      fontSize: "23px",
      lineHeight: "29px",
    },
  },
  headingType: {
    fontFamily: "Poppins",
    fontWeight: 500,
    fontSize: "28px",
    lineHeight: "42px",
    color: "#FFFFFF",
    "@media (max-width: 900px)": {
      fontWeight: 400,
      fontSize: "23px",
      lineHeight: "39px",
    },
    "@media (max-width: 800px)": {
      fontWeight: 400,
      fontSize: "19px",
      lineHeight: "39px",
    },
    "@media (max-width: 600px)": {
      fontWeight: 300,
      fontSize: "11px",
      lineHeight: "20px",
    },
    "@media (max-width: 500px)": {
      fontWeight: 300,
      fontSize: "7px",
      lineHeight: "20px",
    },
  },
}));

export default useStyles;
