import React, { useState } from "react";
import Container from "@mui/material/Container";

import {
  Box,
  Button,
  Grid,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  useMediaQuery,
} from "@mui/material";
import useStyles from "./styles";
import CheckIcon from "@mui/icons-material/Check";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import { Price2 } from "./Price2";
import { Price3 } from "./Price3";
import { Community } from "../community/Community";
import { PriceSection2 } from "./PriceSection2";
export const PriceSection = () => {
  const classes = useStyles();
  const [state, setState] = useState(1);
  const mmatches = useMediaQuery("(max-width:1200px)");
  const thousandmatches = useMediaQuery("(max-width:1000px)");
  const elevenmatches = useMediaQuery("(max-width:1100px)");
  const matches = useMediaQuery("(max-width:600px)");
  const smatches = useMediaQuery("(max-width:400px)");
  const ssmatches = useMediaQuery("(max-width:300px)");
  const clickHandler = (i) => {
    setState(i);
  };
  return (
    <>
      <Container
        maxWidth={
          matches ? "xs" : thousandmatches || elevenmatches ? "sm" : "lg"
        }
      >
        <Box className={classes.heading}>
          Price Based <br />
          on your success
        </Box>
        <Box className={classes.buttonStyling} my={"2rem"}>
          <Button
            variant="text"
            className={classes.button1}
            sx={{
              background:
                state === 1 ? "rgba(243, 52, 125, 0.24)" : "transparent",
              borderRadius: "40px",
              fontWeight: smatches ? 400 : 600,
              fontSize: smatches ? "12px" : "16px",
              paddingX: smatches ? "1.1rem" : "1.3rem",
              color: state === 1 ? "#F3347D" : "#fff",
              "&.MuiButton-root": {
                textTransform: "none",
              },
            }}
            onClick={() => clickHandler(1)}
          >
            Pay monthly
          </Button>
          <Button
            variant="text"
            className={classes.button1}
            sx={{
              background:
                state === 2 ? "rgba(243, 52, 125, 0.24)" : "transparent",
              borderRadius: "40px",
              fontWeight: smatches ? 400 : 600,
              fontSize: smatches ? "12px" : "16px",
              paddingX: smatches ? "1.1rem" : "1.3rem",
              color: state === 2 ? "#F3347D" : "#fff",
              "&.MuiButton-root": {
                textTransform: "none",
              },
            }}
            onClick={() => clickHandler(2)}
          >
            Pay yearly
          </Button>
        </Box>
        {state === 1 ? (
          <>
            {" "}
            <Grid
              container
              spacing={0}
              display="flex"
              justifyContent={"space-between"}
              gap={"2rem"}
            >
              <Grid
                item
                xs={12}
                sm={12}
                md={12}
                lg={3.6}
                
              >
                <Box className={classes.firstgridStyling}>
                  <Box className={classes.boxHeading}>Starter</Box>
                  <Box className={classes.subHeading}>Free</Box>
                  <Box className={classes.sub2Heading}>
                    no credit card require
                  </Box>

                  <Box ml={matches ? "" : mmatches ? "4rem" : ""}>
                    <List sx={{ marginBottom: matches ? "0.2rem" : "2rem " }}>
                      <ListItem disablePadding>
                        <ListItemButton
                          sx={{
                            "&.MuiListItemButton-root": {
                              paddingLeft: "0px !important",
                            },
                          }}
                        >
                          <ListItemIcon
                            sx={{
                              "&.MuiListItemIcon-root": {
                                minWidth: "32px",
                              },
                            }}
                          >
                            <CheckIcon sx={{ color: "#00CA57" }} />
                          </ListItemIcon>
                          <ListItemText
                            primary={
                              <Box className={classes.listedText}>
                                Web3indexer API’s
                              </Box>
                            }
                          />
                        </ListItemButton>
                      </ListItem>
                      <ListItem disablePadding>
                        <ListItemButton
                          sx={{
                            "&.MuiListItemButton-root": {
                              paddingLeft: "0px !important",
                            },
                          }}
                        >
                          <ListItemIcon
                            sx={{
                              "&.MuiListItemIcon-root": {
                                minWidth: "32px",
                              },
                            }}
                          >
                            <CheckIcon sx={{ color: "#00CA57" }} />
                          </ListItemIcon>
                          <ListItemText
                            primary={
                              <Box className={classes.listedText}>
                                web3 authentication
                              </Box>
                            }
                          />
                        </ListItemButton>
                      </ListItem>
                      <ListItem disablePadding>
                        <ListItemButton
                          sx={{
                            "&.MuiListItemButton-root": {
                              paddingLeft: "0px !important",
                            },
                          }}
                        >
                          <ListItemIcon
                            sx={{
                              "&.MuiListItemIcon-root": {
                                minWidth: "32px",
                              },
                            }}
                          >
                            <CheckIcon sx={{ color: "#00CA57" }} />
                          </ListItemIcon>
                          <ListItemText
                            primary={
                              <Box className={classes.listedText}>
                                web3 streams
                              </Box>
                            }
                          />
                        </ListItemButton>
                      </ListItem>
                      <ListItem disablePadding>
                        <ListItemButton
                          sx={{
                            "&.MuiListItemButton-root": {
                              paddingLeft: "0px !important",
                            },
                          }}
                        >
                          <ListItemIcon
                            sx={{
                              "&.MuiListItemIcon-root": {
                                minWidth: "32px",
                              },
                            }}
                          >
                            <CheckIcon sx={{ color: "#00CA57" }} />
                          </ListItemIcon>
                          <ListItemText
                            primary={
                              <Box className={classes.listedText}>
                                Stream internal transaction
                              </Box>
                            }
                          />
                        </ListItemButton>
                      </ListItem>
                      <ListItem disablePadding>
                        <ListItemButton
                          sx={{
                            "&.MuiListItemButton-root": {
                              paddingLeft: "0px !important",
                            },
                          }}
                        >
                          <ListItemIcon
                            sx={{
                              "&.MuiListItemIcon-root": {
                                minWidth: "32px",
                              },
                            }}
                          >
                            <CheckIcon sx={{ color: "#00CA57" }} />
                          </ListItemIcon>
                          <ListItemText
                            primary={
                              <Box className={classes.listedText}>
                                Web3indexer API’s
                              </Box>
                            }
                          />
                        </ListItemButton>
                      </ListItem>
                      <ListItem disablePadding>
                        <ListItemButton
                          sx={{
                            "&.MuiListItemButton-root": {
                              paddingLeft: "0px !important",
                            },
                          }}
                        >
                          <ListItemIcon
                            sx={{
                              "&.MuiListItemIcon-root": {
                                minWidth: "32px",
                              },
                            }}
                          >
                            <CheckIcon sx={{ color: "#00CA57" }} />
                          </ListItemIcon>
                          <ListItemText
                            primary={
                              <Box className={classes.listedText}>
                                Community Support
                              </Box>
                            }
                          />
                        </ListItemButton>
                      </ListItem>
                    </List>
                  </Box>
                  {matches ? (
                    <Button
                      variant="contained"
                      fullWidth
                      sx={{
                        "&.MuiButton-root": {
                          color: "#fff !important",
                          fontFamily: "Poppins",
                          fontWeight: matches ? 400 : 600,
                          fontSize: ssmatches
                            ? "11px"
                            : matches
                            ? "12px"
                            : "16px",
                          backgroundColor: "#7733A1",
                          borderRadius: "8px",
                          paddingY: "0.6rem",
                        },
                      }}
                      endIcon={<ArrowForwardIcon />}
                    >
                      Get Started for free
                    </Button>
                  ) : (
                    <Box className={classes.boxbutton}>
                      <Button
                        variant="contained"
                        fullWidth
                        sx={{
                          "&.MuiButton-root": {
                            color: "#fff !important",
                            fontFamily: "Poppins",
                            fontWeight: matches ? 400 : 600,
                            fontSize: matches ? "14px" : "16px",
                            backgroundColor: "#7733A1",
                            borderRadius: "8px",
                            padding: smatches
                              ? "0.7rem 1.3rem"
                              : matches
                              ? "0.7rem 1.8rem"
                              : "0.7rem 2.4rem",
                          },
                        }}
                        endIcon={<ArrowForwardIcon />}
                      >
                        Get Started for free
                      </Button>
                    </Box>
                  )}
                </Box>
              </Grid>
              <Grid
                item
                xs={12}
                sm={12}
                md={12}
                lg={3.6}
                
              >
                <Price2 matches={matches} smatches={smatches} />
              </Grid>
              <Grid
                item
                xs={12}
                sm={12}
                md={12}
                lg={3.6}
                
              >
                <Price3 matches={matches} smatches={smatches} />
              </Grid>
            </Grid>
          </>
        ) : (
          <>
            <PriceSection2 />
          </>
        )}
      </Container>
      <Community />
    </>
  );
};
