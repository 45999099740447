import {
  Box,
  Container,
  Grid,
  LinearProgress,
  useMediaQuery,
} from "@mui/material";
import React from "react";
import useStyles from "./styles";
import { BuildSection } from "../BuildSection/BuildSection";
import Lottie from "lottie-react";
import backboneBlockchain from "../../lotties/Backbone_Blockchain.json";
import { useInView } from "react-intersection-observer";
import CountUp from "react-countup";
export const BlockChainData = () => {
  const classes = useStyles();
  const matches = useMediaQuery("(max-width:600px)");
  const { ref, inView } = useInView({
    triggerOnce: true,
    rootMargin: "-100px 0px",
  });

  return (
    <>
      <Container maxWidth="lg">
        <Grid container spacing={0} gap={matches ? "4rem" : "8rem"}>
          <Grid
            item
            xs={12}
            sm={12}
            md={12}
            lg={5.3}
            className={classes.gridresponsiveness}
            flexDirection={"column"}
          >
            <Box className={classes.heading}>
              The Backbone for Blockchain Data
            </Box>
            <Box className={classes.subHeading}>
              Follow the Smart Money, see wherein price range are shifting to,
              pick out new tasks or tokens, and hint transactions right all the
              way down to the maximum granular level.
            </Box>
            <Box className={classes.rowStyling}>
              <LinearProgress
                ref={ref}
                classes={{
                  bar1Indeterminate: inView && classes.bar1Indeterminate,
                  bar2Indeterminate: classes.bar2Indeterminate,
                }}
                variant="indeterminate"
                sx={{
                  ".MuiLinearProgress-barColorPrimary": {
                    background: inView
                      ? "linear-gradient(90deg, #5C1D94 0%, #7733A1 34.37%, #B12396 68.75%, #E30077 100%)!important"
                      : "transparent",
                  },
                  "&.MuiLinearProgress-root": {
                    backgroundColor: "transparent !important",
                  },
                }}
                className={classes.linearProgress}
              />
              <Box className={classes.data}>
                <Box className={classes.numbring}>
                  250M+
                </Box>
                <Box className={classes.content}>Labeled Addresses</Box>
              </Box>
            </Box>
            {/* //SECOND ROW */}
            <Box className={classes.rowStyling}>
              <LinearProgress
                ref={ref}
                classes={{
                  bar1Indeterminate: inView && classes.bar1Indeterminate,
                  bar2Indeterminate: classes.bar2Indeterminate,
                }}
                variant="indeterminate"
                sx={{
                  ".MuiLinearProgress-barColorPrimary": {
                    background: inView
                      ? "linear-gradient(90deg, #5C1D94 0%, #7733A1 34.37%, #B12396 68.75%, #E30077 100%)!important"
                      : "transparent",
                  },
                  "&.MuiLinearProgress-root": {
                    backgroundColor: "transparent !important",
                  },
                }}
                className={classes.linearProgress}
                value={100}
              />
              <Box className={classes.data2}>
                <Box className={classes.numbring}>
                  95%
                </Box>
                <Box className={classes.content}>Of All On-Chain TVL</Box>
              </Box>
            </Box>
            {/* //!Third ROW */}
            <Box className={classes.rowStyling}>
              <LinearProgress
                ref={ref}
                classes={{
                  bar1Indeterminate: inView && classes.bar1Indeterminate,
                  bar2Indeterminate: classes.bar2Indeterminate,
                }}
                variant="indeterminate"
                sx={{
                  ".MuiLinearProgress-barColorPrimary": {
                    background: inView
                      ? "linear-gradient(90deg, #5C1D94 0%, #7733A1 34.37%, #B12396 68.75%, #E30077 100%)!important"
                      : "transparent",
                  },
                  "&.MuiLinearProgress-root": {
                    backgroundColor: "transparent !important",
                  },
                }}
                className={classes.linearProgress}
                value={100}
              />
              <Box className={classes.data}>
                <Box className={classes.numbring}>
                  500TB
                </Box>
                <Box className={classes.content}>Data Processed Daily</Box>
              </Box>
            </Box>
          </Grid>
          <Grid
            item
            xs={12}
            sm={12}
            md={12}
            lg={5}
            mt={{ xs: "1rem", md: "0rem" }}
          >
            <Lottie
              animationData={backboneBlockchain}
              loop={true}
              style={{ width: "100%" }}
            />
          </Grid>
        </Grid>
      </Container>
      <BuildSection />
    </>
  );
};
