import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  boxstyling: {
    background: "rgba(255, 255, 255, 0.1)",
    boxShadow:
      "0px 4px 4px rgba(0, 0, 0, 0.25), inset 0px 4px 20px rgba(255, 255, 255, 0.15)",
    borderRadius: "16px",
    display: "flex",
    flexDirection: "column",
    padding: "2rem 1rem",
  },
  topText: {
    fontFamily: "Poppins",

    fontWeight: 700,
    fontSize: "16px",
    textAlign: "center",
    color: "#C138BB",
    // marginTop: "2rem",
  },
  heading: {
    fontFamily: "Poppins",
    fontWeight: 700,
    fontSize: " 40px",
    textAlign: "center",
    marginTop: "1rem",
    marginBottom: "2rem",
    color: "#FFFFFF",
    "@media (max-width: 680px)": {
      fontWeight: 700,
      fontSize: " 24px",
      marginBottom: "1rem",
      // textAlign: "start",
    },
  },
  content: {
    fontFamily: "Poppins",
    color: "#FFFFFF",
    fontWeight: 400,
    fontSize: "16px",
    textAlign: "center",
    "@media (max-width: 600px)": {
      fontSize: "14px",
      // textAlign: "start",
      alignItems: "center",
    },
    "@media (max-width: 400px)": {
      fontSize: "12px",
    },
  },
  input: {
    padding: "0.8rem 1rem",
    background: "transparent",
    border: "1px solid #fff",
    borderRadius: "8px",
    width: "100%",
  },

  inputStyling: {
    width: "100%",
    maxWidth: "45%",
    "@media (max-width: 800px)": {
      maxWidth: "100%",
    },
  },
  inputbox: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    gap: "2rem",
    marginTop: "3rem",
    marginBottom: "2rem",
    "@media (max-width: 800px)": {
      flexDirection: "column",
    },

    "@media (max-width: 600px)": {
      marginTop: "2rem",
      gap: "1rem",
      marginBottom: "0rem",
    },
  },
}));
export default useStyles;
