import { Container, Box, Grid, Divider, useMediaQuery } from "@mui/material";
import React from "react";
import useStyles from "./styles";
import hooked from "../../assets/hooked.png";
import graph from "../../assets/graph.png";
import safea from "../../assets/safea.png";
import velas from "../../assets/velas.png";
import cmc from "../../assets/cmc.png";
import cronos from "../../assets/cronos.png";
import fancy from "../../assets/fncy.png";
import { RoadMap } from "../RoadMapSection/RoadMap";
import Lottie from "lottie-react";
import animationRpc from "../../lotties/RPC_Service.json";
import mobileicon from "../../assets/MobileIcon.png";
const FeaturedSection = () => {
  const classes = useStyles();
  const smatches = useMediaQuery("(max-width:1200px)");
  const sxmatches = useMediaQuery("(max-width:900px)");
  const eightmatches = useMediaQuery("(max-width:800px)");
  const sxxmatches = useMediaQuery("(max-width:667px)");
  const fivematches = useMediaQuery("(max-width:500px)");
  const fourmatches = useMediaQuery("(max-width:440px)");
  const lessfourmatches = useMediaQuery("(max-width:400px)");
  const twomatches = useMediaQuery("(max-width:330px)");
  const threematches = useMediaQuery("(max-width:300px)");

  return (
    <>
      <Container
        maxWidth="md"
        sx={{
          "&.MuiContainer-root": {
            maxWidth: "1084px",
          },
        }}
      >
        <Box mt={sxxmatches ? "3rem" : "5rem"}>
          <Box className={classes.smallheading}>Featured</Box>
          <Box className={classes.heading} textAlign={"center"} my={2}>
            Explore the Best Web3 APIs <br /> in Our Marketplace
          </Box>
        </Box>

        <Box
          my={3}
          className={classes.buttonfeatured}
          py={fourmatches ? "1rem" : sxxmatches ? "2rem" : "3rem"}
        >
          <Box className={classes.textsBox}>
            <Box
              ml={
                fourmatches
                  ? "0.5rem"
                  : fivematches
                  ? "1rem"
                  : sxxmatches
                  ? "2rem"
                  : "4rem"
              }
            >
              {eightmatches ? (
                <Box className={classes.onetext}>Easy Build</Box>
              ) : (
                <Box className={classes.onetext}>Easy to Build</Box>
              )}

              {eightmatches ? (
                ""
              ) : (
                <Box className={classes.twotext}>
                  Seamlessly connect to your dApps.
                </Box>
              )}
            </Box>
            <Box
              textAlign={"end"}
              mr={
                fourmatches
                  ? "0.5rem"
                  : fivematches
                  ? "1rem"
                  : sxxmatches
                  ? "0rem"
                  : eightmatches
                  ? "2rem"
                  : "4rem"
              }
            >
              <Box className={classes.onetext}>Reliable Service</Box>
              {eightmatches ? (
                ""
              ) : (
                <Box className={classes.twotext}>
                  Real-time monitoring service status.
                </Box>
              )}
            </Box>
          </Box>
          {eightmatches ? (
            <Box className={classes.responsiveImage}>
              <img
                src={mobileicon}
                alt={"responsive"}
                width={threematches ? "34%" : "auto"}
              />
            </Box>
          ) : (
            <Lottie
              animationData={animationRpc}
              loop={false}
              style={{ width: "100%" }}
            />
          )}

          <Box
            className={classes.textsBox}
            mt={sxmatches ? "-0.9rem" : "-1.5rem"}
          >
            <Box
              ml={
                fourmatches
                  ? "0.5rem"
                  : fivematches
                  ? "1rem"
                  : sxxmatches
                  ? "2rem"
                  : "4rem"
              }
            >
              <Box className={classes.onetext}>
                {eightmatches ? "Scale Ease" : "Scale with Ease"}
              </Box>
              {eightmatches ? (
                ""
              ) : (
                <Box className={classes.twotext}>No scaling limitations.</Box>
              )}
            </Box>
            {eightmatches ? (
              ""
            ) : (
              <Box
                ml={fourmatches ? "0.5rem" : fivematches ? "0rem" : "4rem"}
                mt={fivematches ? "0rem" : "0.8rem"}
              >
                <Box className={classes.headingType}>The Best RPC Service</Box>
              </Box>
            )}

            <Box
              textAlign={"end"}
              mr={
                fivematches
                  ? "1rem"
                  : sxxmatches
                  ? "0rem"
                  : eightmatches
                  ? "2rem"
                  : "4rem"
              }
            >
              <Box className={classes.onetext}>
                {eightmatches ? " Data Accuracy" : "Extreme Data Accuracy"}
              </Box>
              {eightmatches ? (
                ""
              ) : (
                <Box className={classes.twotext}>Ensure data availability.</Box>
              )}
            </Box>
          </Box>
        </Box>
      </Container>

      <Container maxWidth="lg">
        {eightmatches ? (
          <Box className={classes.outsideHeading}>The Best RPC Service</Box>
        ) : (
          ""
        )}
        <Grid
          container
          spacing={0}
          className={classes.gridcontainer}
          mt={sxxmatches ? 2 : 5}
          mb={fivematches ? 2 : 5}
        >
          <Grid xs={5.8} sm={5.9} md={5.9} lg={2.2}>
            <Box className={classes.walletData}>
              <Box className={classes.titlewalletData} mt={1}>
                NFT
              </Box>
              <Divider className={classes.dividerLine} />
              <Box className={classes.tripleImgeBox}>
                <Box>
                  {" "}
                  <img src={hooked} alt="" srcset="" width="100%" />
                </Box>
                <Box>
                  <img src={safea} alt="" srcset="" width="100%" />
                </Box>
                <Box>
                  <img src={fancy} alt="" srcset="" width="100%" />
                </Box>
              </Box>
              <Box className={classes.doubleImgeBox1} mb={"-0.4rem"}>
                <Box>
                  {" "}
                  <img src={cmc} alt="" srcset="" width={"90%"} />
                </Box>
                <Box>
                  {" "}
                  <img src={graph} alt="" srcset="" />
                </Box>
              </Box>
              <Box className={classes.doubleImgeBox2} mb={"1rem"}>
                <Box>
                  {" "}
                  <img src={cronos} alt="" srcset="" />
                </Box>
                <Box>
                  {" "}
                  <img src={velas} alt="" srcset="" />
                </Box>
              </Box>
            </Box>
          </Grid>
          <Grid xs={5.8} sm={5.9} md={5.9} lg={2.2}>
            <Box className={classes.walletData}>
              <Box className={classes.titlewalletData} mt={1}>
                DeFi
              </Box>
              <Divider className={classes.dividerLine} />
              <Box className={classes.tripleImgeBox}>
                <Box>
                  {" "}
                  <img src={hooked} alt="" srcset="" width="100%" />
                </Box>
                <Box>
                  <img src={safea} alt="" srcset="" width="100%" />
                </Box>
                <Box>
                  <img src={fancy} alt="" srcset="" width="100%" />
                </Box>
              </Box>
              <Box className={classes.doubleImgeBox1} mb={"-0.4rem"}>
                <Box>
                  {" "}
                  <img src={cmc} alt="" srcset="" width={"90%"} />
                </Box>
                <Box>
                  {" "}
                  <img src={graph} alt="" srcset="" />
                </Box>
              </Box>
              <Box className={classes.doubleImgeBox2} mb={"1rem"}>
                <Box>
                  {" "}
                  <img src={cronos} alt="" srcset="" />
                </Box>
                <Box>
                  {" "}
                  <img src={velas} alt="" srcset="" />
                </Box>
              </Box>
            </Box>
          </Grid>
          <Grid xs={5.8} sm={5.9} md={5.9} lg={2.2}>
            <Box className={classes.walletData}>
              <Box className={classes.titlewalletData} mt={1}>
                GameFi
              </Box>

              <Divider className={classes.dividerLine} />
              <Box className={classes.tripleImgeBox}>
                <Box>
                  <img src={hooked} alt="" srcset="" width="100%" />
                </Box>
                <Box>
                  <img src={safea} alt="" srcset="" width="100%" />
                </Box>
                <Box>
                  <img src={fancy} alt="" srcset="" width="100%" />
                </Box>
              </Box>
              <Box className={classes.doubleImgeBox1} mb={"-0.4rem"}>
                <Box>
                  {" "}
                  <img src={cmc} alt="" srcset="" width={"90%"} />
                </Box>
                <Box>
                  {" "}
                  <img src={graph} alt="" srcset="" />
                </Box>
              </Box>
              <Box className={classes.doubleImgeBox2} mb={"1rem"}>
                <Box>
                  {" "}
                  <img src={cronos} alt="" srcset="" />
                </Box>
                <Box>
                  {" "}
                  <img src={velas} alt="" srcset="" />
                </Box>
              </Box>
            </Box>
          </Grid>
          <Grid xs={5.8} sm={5.9} md={5.9} lg={2.2}>
            <Box className={classes.walletData}>
              <Box className={classes.titlewalletData} mt={1}>
                Wallet
              </Box>
              <Divider className={classes.dividerLine} />
              <Box className={classes.tripleImgeBox}>
                <Box>
                  {" "}
                  <img src={hooked} alt="" srcset="" width="100%" />
                </Box>
                <Box>
                  <img src={safea} alt="" srcset="" width="100%" />
                </Box>
                <Box>
                  <img src={fancy} alt="" srcset="" width="100%" />
                </Box>
              </Box>
              <Box className={classes.doubleImgeBox1} mb={"-0.4rem"}>
                <Box>
                  {" "}
                  <img src={cmc} alt="" srcset="" width={"90%"} />
                </Box>
                <Box>
                  {" "}
                  <img src={graph} alt="" srcset="" />
                </Box>
              </Box>
              <Box className={classes.doubleImgeBox2} mb={"1rem"}>
                <Box>
                  {" "}
                  <img src={cronos} alt="" srcset="" />
                </Box>
                <Box>
                  {" "}
                  <img src={velas} alt="" srcset="" />
                </Box>
              </Box>
            </Box>
          </Grid>
          <Grid
            xs={12}
            sm={12}
            md={12}
            lg={2.2}
            display={smatches ? "flex" : "block"}
            justifyContent={"center"}
          >
            <Box className={classes.walletData}>
              <Box
                className={classes.titlewalletData}
                mt={1}
                paddingX={
                  threematches
                    ? "2.8rem"
                    : twomatches
                    ? "3rem"
                    : lessfourmatches
                    ? "4rem"
                    : fourmatches
                    ? "4.6rem"
                    : fivematches
                    ? "5.6rem"
                    : sxxmatches
                    ? "7.4rem"
                    : sxmatches
                    ? "11.6rem"
                    : smatches
                    ? "16rem"
                    : ""
                }
              >
                Data
              </Box>
              <Divider className={classes.dividerLine} />
              <Box className={classes.tripleImgeBox}>
                <Box>
                  {" "}
                  <img src={hooked} alt="" srcset="" />
                </Box>
                <Box>
                  <img src={safea} alt="" srcset="" />
                </Box>
                <Box>
                  <img src={fancy} alt="" srcset="" />
                </Box>
              </Box>
              <Box className={classes.doubleImgeBox1} mb={"-0.4rem"}>
                <Box>
                  {" "}
                  <img src={cmc} alt="" srcset="" width={"90%"} />
                </Box>
                <Box>
                  {" "}
                  <img src={graph} alt="" srcset="" />
                </Box>
              </Box>
              <Box className={classes.doubleImgeBox2} mb={"1rem"}>
                <Box>
                  {" "}
                  <img src={cronos} alt="" srcset="" />
                </Box>
                <Box>
                  {" "}
                  <img src={velas} alt="" srcset="" />
                </Box>
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Container>
      <RoadMap />
    </>
  );
};

export default FeaturedSection;
