import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  heading: {
    fontFamily: "Poppins",
    fontWeight: 700,
    fontSize: "48px",
    color: "#fff",
    textAlign: "center",
    "@media (max-width: 600px)": {
      fontWeight: 700,
      fontSize: "44px",
    },
    "@media (max-width: 400px)": {
      fontWeight: 600,
      fontSize: "34px",
    },
  },
  boxstyling: {
    background: "rgba(255, 255, 255, 0.09)",
    boxShadow: "inset 0px 4px 40px rgba(255, 255, 255, 0.15)",
    backdropFilter: "blur(40px)",
    borderRadius: "20px",
    padding: "2rem",
    display: "flex",
    flexDirection: "column",
    gap: "1rem",
    "@media (max-width: 400px)": {
      gap: "0rem",
    },
    "@media (max-width: 600px)": {
      padding: "1.2rem",
    },
  },
  boxHeading: {
    fontFamily: "Poppins",
    fontWeight: 600,
    fontSize: "36px",
    color: "#fff",
    "@media (max-width: 600px)": {
      fontWeight: 600,
      fontSize: "31px",
    },
    "@media (max-width: 400px)": {
      fontWeight: 600,
      fontSize: "24px",
    },
  },
  content: {
    fontFamily: "Poppins",
    fontWeight: 400,
    fontSize: "18px",
    lineHeight: "27px",

    color: "#EBEBEB",
    marginBottom: "5rem",
    "@media (max-width: 600px)": {
      fontWeight: 400,
      fontSize: "15px",
      marginBottom: "3rem",
    },
    "@media (max-width: 400px)": {
      fontWeight: 400,
      fontSize: "12px",
      marginBottom: "2rem",
    },
  },
  content2: {
    fontFamily: "Poppins",
    fontWeight: 400,
    fontSize: "18px",
    lineHeight: "27px",
    color: "#EBEBEB",
    marginBottom: "1.6rem",
    "@media (max-width: 600px)": {
      fontWeight: 400,
      fontSize: "15px",
      marginBottom: "3rem",
    },
    "@media (max-width: 400px)": {
      fontWeight: 400,
      fontSize: "12px",
      marginBottom: "2rem",
    },
  },
  buttonfirst: {
    borderRadius: "8px",

    fontFamily: "Poppins",
    fontSize: "16px",
  },
  maingrid: {
    display: "flex",
    justifyContent: "space-between",
    "@media (max-width: 1200px)": {
      gap: "2rem",
    },
  },
}));

export default useStyles;
