import React from "react";

import {
  Box,
  Button,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  useMediaQuery,
} from "@mui/material";
import useStyles from "./styles";
import CheckIcon from "@mui/icons-material/Check";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
export const Price3 = () => {
  const classes = useStyles();
  const smatches = useMediaQuery("(max-width:400px)");
  const mmatches = useMediaQuery("(max-width:1200px)");
  const ssmatches = useMediaQuery("(max-width:300px)");
  const matches = useMediaQuery("(max-width:600px)");
  return (
    <Box className={classes.thirdgridStyling}>
      <Box className={classes.boxHeading3}>Enterprise</Box>
      <Box className={classes.subHeading}>Custom</Box>
      <Box className={classes.sub2Heading}>Contact us for solution</Box>

      <Box ml={matches ? "" : mmatches ? "4rem" : ""}>
        <List sx={{ marginBottom: matches ? "0.2rem" : "2rem " }}>
          <ListItem disablePadding>
            <ListItemButton
              sx={{
                "&.MuiListItemButton-root": {
                  paddingLeft: "0px !important",
                },
              }}
            >
              <ListItemIcon
                sx={{
                  "&.MuiListItemIcon-root": {
                    minWidth: "32px",
                  },
                }}
              >
                <CheckIcon sx={{ color: "#00CA57" }} />
              </ListItemIcon>
              <ListItemText
                primary={
                  <Box className={classes.listedText}>Web3indexer API’s</Box>
                }
              />
            </ListItemButton>
          </ListItem>
          <ListItem disablePadding>
            <ListItemButton
              sx={{
                "&.MuiListItemButton-root": {
                  paddingLeft: "0px !important",
                },
              }}
            >
              <ListItemIcon
                sx={{
                  "&.MuiListItemIcon-root": {
                    minWidth: "32px",
                  },
                }}
              >
                <CheckIcon sx={{ color: "#00CA57" }} />
              </ListItemIcon>
              <ListItemText
                primary={
                  <Box className={classes.listedText}>web3 authentication</Box>
                }
              />
            </ListItemButton>
          </ListItem>
          <ListItem disablePadding>
            <ListItemButton
              sx={{
                "&.MuiListItemButton-root": {
                  paddingLeft: "0px !important",
                },
              }}
            >
              <ListItemIcon
                sx={{
                  "&.MuiListItemIcon-root": {
                    minWidth: "32px",
                  },
                }}
              >
                <CheckIcon sx={{ color: "#00CA57" }} />
              </ListItemIcon>
              <ListItemText
                primary={<Box className={classes.listedText}>web3 streams</Box>}
              />
            </ListItemButton>
          </ListItem>
          <ListItem disablePadding>
            <ListItemButton
              sx={{
                "&.MuiListItemButton-root": {
                  paddingLeft: "0px !important",
                },
              }}
            >
              <ListItemIcon
                sx={{
                  "&.MuiListItemIcon-root": {
                    minWidth: "32px",
                  },
                }}
              >
                <CheckIcon sx={{ color: "#00CA57" }} />
              </ListItemIcon>
              <ListItemText
                primary={
                  <Box className={classes.listedText}>
                    Stream internal transaction
                  </Box>
                }
              />
            </ListItemButton>
          </ListItem>
          <ListItem disablePadding>
            <ListItemButton
              sx={{
                "&.MuiListItemButton-root": {
                  paddingLeft: "0px !important",
                },
              }}
            >
              <ListItemIcon
                sx={{
                  "&.MuiListItemIcon-root": {
                    minWidth: "32px",
                  },
                }}
              >
                <CheckIcon sx={{ color: "#00CA57" }} />
              </ListItemIcon>
              <ListItemText
                primary={
                  <Box className={classes.listedText}>Web3indexer API’s</Box>
                }
              />
            </ListItemButton>
          </ListItem>
          <ListItem disablePadding>
            <ListItemButton
              sx={{
                "&.MuiListItemButton-root": {
                  paddingLeft: "0px !important",
                },
              }}
            >
              <ListItemIcon
                sx={{
                  "&.MuiListItemIcon-root": {
                    minWidth: "32px",
                  },
                }}
              >
                <CheckIcon sx={{ color: "#00CA57" }} />
              </ListItemIcon>
              <ListItemText
                primary={
                  <Box className={classes.listedText}>Community Support</Box>
                }
              />
            </ListItemButton>
          </ListItem>
        </List>
      </Box>
      {matches ? (
        <Button
          variant="contained"
          fullWidth
          sx={{
            "&.MuiButton-root": {
              color: "#fff !important",
              fontFamily: "Poppins",
              fontWeight: matches ? 400 : 600,
              fontSize: ssmatches ? "11px" : matches ? "12px" : "16px",
              backgroundColor: "#E30077",

              borderRadius: "8px",
              paddingY:  "0.6rem",
            },
          }}
          endIcon={<ArrowForwardIcon />}
        >
          Contact Us
        </Button>
      ) : (
        <Box className={classes.boxbutton}>
          <Button
            variant="contained"
            fullWidth
            sx={{
              "&.MuiButton-root": {
                color: "#fff !important",
                fontFamily: "Poppins",
                fontWeight: matches ? 400 : 600,
                fontSize: matches ? "14px" : "16px",
                backgroundColor: "#E30077",

                borderRadius: "8px",
                padding: smatches
                  ? "0.7rem 1.3rem"
                  : matches
                  ? "0.7rem 1.8rem"
                  : "0.7rem 2.4rem",
              },
            }}
            endIcon={<ArrowForwardIcon />}
          >
            Contact Us
          </Button>
        </Box>
      )}
    </Box>
  );
};
