import React from "react";
import {
  Grid,
  useMediaQuery,
} from "@mui/material";

import { Price2 } from "./Price2";
import { Price3 } from "./Price3";

export const PriceSection2 = () => {
 
  const matches = useMediaQuery("(max-width:600px)");
  const smatches = useMediaQuery("(max-width:400px)");
 
  return (
    <Grid
      container
      spacing={0}
      display="flex"
      justifyContent={"space-between"}
      gap={"2rem"}
    >
      <Grid item xs={12} sm={12} md={12} lg={3.6} >
        <Price2 matches={matches} smatches={smatches} />
      </Grid>
      <Grid item xs={12} sm={12} md={12} lg={3.6} >
        <Price3 matches={matches} smatches={smatches} />
      </Grid>
      <Grid item xs={12} sm={12} md={12} lg={3.6} >
        <Price3 matches={matches} smatches={smatches} />
      </Grid>
    </Grid>
  );
};
