import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  buttonfeatured: {
    display: "flex",
    justifyContent: "center",
  },
  root: {
    width: "100%",
  },
  bar1Indeterminate: {
    width: "auto",
    animation: "$indeterminate1 2s linear forwards !important",
  },
  bar2Indeterminate: {
    display: "none",
  },
  "@keyframes indeterminate1": {
    "0%": {
      left: "-15%",
      right: "100%",
    },
    "100%": {
      left: "0%",
      right: "0%",
    },
  },
  card: {
    background: "rgba(255, 255, 255, 0.06)",
    boxShadow: "inset 0px 4px 40px 8px rgba(255, 255, 255, 0.15)",
    backdropFilter: "blur(40px)",

    borderRadius: "30px",
    padding: "3rem 2rem",
  },
  cardHeading: {
    fontFamily: "Poppins",
    fontWeight: 700,
    fontSize: "48px",
    lineHeight: "56px",
    color: "#fff",
    "@media (max-width: 1200px)": {
      display: "flex",
      textAlign: "center",
      justifyContent: "center",
    },
    "@media (max-width: 700px)": {
      fontWeight: 600,
      fontSize: "40px",
      textAlign: "start",
      justifyContent: "start",
      lineHeight: "50px",
    },
    "@media (max-width: 600px)": {
      fontWeight: 600,
      fontSize: "30px",
      lineHeight: "40px",
    },
  },
  cardsec: {
    fontFamily: "Poppins",
    fontWeight: 700,
    fontSize: "32px",
    color: "#fff",
    lineHeight: "25px",
    display: "flex",
    justifyContent: "end",
    "@media (max-width: 700px)": {
      fontWeight: 600,
      fontSize: "25px",
    },
    "@media (max-width: 630px)": {
      fontWeight: 600,
      fontSize: "20px",
    },
    "@media (max-width: 400px)": {
      fontWeight: 500,
      fontSize: "12px",
    },
  },
  cardsecContent: {
    fontFamily: "Poppins",
    fontWeight: 500,
    fontSize: "12px",
    color: "#fff",
    "@media (max-width: 700px)": {
      fontWeight: 600,
      fontSize: "9px",
    },
    "@media (max-width: 400px)": {
      fontWeight: 500,
      fontSize: "6px",
    },
  },
  cardrow: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  competitiorA: {
    fontFamily: "Poppins",
    color: "#fff",
    fontWeight: 600,
    fontSize: "24px",
    "@media (max-width: 700px)": {
      fontWeight: 600,
      fontSize: "21px",
    },
    "@media (max-width: 630px)": {
      fontWeight: 600,
      fontSize: "18px",
    },
    "@media (max-width: 400px)": {
      fontWeight: 500,
      fontSize: "11px",
    },
  },
  competiABox: {
    display: "flex",
    alignItems: "end",
    gap: "1rem",
  },
  cardrow2: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  
  },
  cardrow3: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  
  },
  cardrow4: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  text: {
    fontFamily: "Poppins",
    fontWeight: 600,
    fontSize: "28px",
    color: "#fff",
    "@media (max-width: 1200px)": {
      display: "flex",
      textAlign: "center",
      justifyContent: "center",
      marginTop: "1rem",
    },
    "@media (max-width: 700px)": {
      fontWeight: 500,
      fontSize: "20px",
      textAlign: "start",
      justifyContent: "start",
      marginTop: "0rem",
    },
    "@media (max-width: 400px)": {
      fontWeight: 400,
      fontSize: "12px",
    },
  },
  littlText: {
    fontFamily: "Poppins",
    fontWeight: 600,
    fontSize: "16px",
    color: "#C138BB",
    display: "flex",
    alignItems: "center",
    gap: "0.2rem",
    "@media (max-width: 1200px)": {
      display: "flex",
      textAlign: "center",
      justifyContent: "center",
    },
    "@media (max-width: 700px)": {
      textAlign: "start",
      justifyContent: "start",
    },
  },
  stylingGrid: {
    display: "flex",
    justifyContent: "center",
    flexDirection: "column",
  },
  imgAdjust: {
    position: "relative",
    zIndex: -1,
  },
  vedioresponsive: {
    width: "100%",

    "@media (max-width: 600px)": {
      height: "58vh",
      objectFit: "cover",
    },
  },
}));

export default useStyles;
