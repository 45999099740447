import React, { useState } from "react";
import {
  Box,
  Container,
  useMediaQuery,
  Grid,
  Hidden,
  Select,
  MenuItem,
} from "@mui/material";
import SwipeableDrawer from "@mui/material/SwipeableDrawer";
import Button from "@mui/material/Button";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import { FormControl } from "@material-ui/core";
import clsx from "clsx";
import { Paper } from "@mui/material";
import logo from "../../assets/Logo.png";
import MenuIcon from "@mui/icons-material/Menu";
import useStyles from "./styles";

function Navbar() {
  const classes = useStyles();
  const [state, setState] = useState(false);
  const elevenmatches = useMediaQuery("(max-width:1100px)");
  const matches = useMediaQuery("(max-width:960px)");
  const sixsmatches = useMediaQuery("(max-width:600px)");
  const xxsmatches = useMediaQuery("(max-width:500px)");
  const llastsmatches = useMediaQuery("(max-width:300px)");
  const [age, setAge] = React.useState("10");

  const handleChange = (event) => {
    setAge(event.target.value);
  };

  const toggleDrawer = (anchor, open) => (event) => {
    if (
      event &&
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    setState({ ...state, [anchor]: open });
  };
  const list = (anchor) => (
    <div
      className={clsx(classes.list, {
        [classes.fullList]: anchor === "top" || anchor === "bottom",
      })}
      role="presentation"
      onClick={toggleDrawer(anchor, false)}
      onKeyDown={toggleDrawer(anchor, false)}
    >
      <Box mb={5} display="flex" justifyContent="center">
        <Box
          // fontFamily="Roboto"
          mt={1}
        >
          <Box display="flex" justifyContent={"center"}>
            <img
              src={logo}
              alt="logo"
              width={sixsmatches ? "70%" : "90%"}
              height={"auto"}
            />
          </Box>
        </Box>
      </Box>
      <List>
        {[
          <>
            <Box>Products</Box>

            <Box>NFT Marketplace</Box>

            <Box>NFT Explore</Box>

            <Box>Web3 Store</Box>
            <Box>Developers</Box>
            <Box>Blog</Box>
          </>,
        ].map((text, index) => (
          <ListItem
            sx={{
              alignItems: "center",
              textAlign: "center",
              //   gap: "1rem",
            }}
            key={text}
          >
            <ListItemText
              className={classes.selectText}
              primary={
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "1rem",
                  }}
                >
                  {text}
                </Box>
              }
            />
          </ListItem>
        ))}
      </List>
      <Box mt={5} display="flex" justifyContent="center">
        <Button
          variant="contained"
          sx={{
            borderRadius: "8px",
            color: "#EBEBEB",
            fontFamily: "Poppins",
            fontSize: "14px",
            background:
              "linear-gradient(90deg, #5C1D94 0%, #7733A1 34.37%, #B12396 68.75%, #E30077 100%)",
            paddingX: "2rem",
            "&.MuiButton-root": {
              textTransform: "none",
            },
            "&.MuiButton-root:hover": {
              backgroundColor: "#1E50FF",
            },
          }}
          className={classes.selectText}
        >
          Join Us
        </Button>
      </Box>
    </div>
  );

  return (
    <Box position="relative">
      <Box
        bgcolor="transparent"
        // height="100px"
        display="flex"
        justifyContent="center"
        alignItems="center"
        py={llastsmatches ? 0 : xxsmatches || sixsmatches ? 1 : 2}
      >
        <Container
          maxWidth="xl"
          sx={{
            "&.MuiContainer-root": {
              maxWidth: "1303px",
            },
          }}
        >
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
          >
            <Box
              display="flex"
              //   justifyContent="space-between"
              alignItems="center"
              //   flexBasis={matches ? "100%" : "14%"}
            >
              <Grid
                container
                direction="row"
                justifyContent="start"
                alignItems="center"
              >
                <Grid item>
                  <Box
                    // fontFamily="Roboto"
                    fontWeight={300}
                    fontSize={matches ? "25px" : "30px"}
                  >
                    <a href="/">
                      <Box>
                        <img
                          src={logo}
                          alt="logo"
                          width={"100%"}
                          // height={"auto"}
                        />
                      </Box>
                    </a>
                  </Box>
                </Grid>
              </Grid>
            </Box>
            <Box display={{ xs: "none", sm: "block" }}>
              <Hidden mdDown>
                <Box
                  display="flex"
                  justifyContent="space-evenly"
                  alignItems="center"
                  gap={"1rem"}
                >
                  <FormControl variant="standard" sx={{ m: 1, minWidth: 120 }}>
                    <Select
                      value={age}
                      onChange={handleChange}
                      MenuProps={{
                        disableScrollLock: true,
                      }}
                      sx={{
                        cursor: "pointer",
                        ".MuiOutlinedInput-notchedOutline": { border: 0 },
                        ".MuiOutlinedInput-input": {
                          border: 0,
                          padding: "0.5rem 2.3rem 0.5rem 1rem !important",
                        },
                        ".MuiInputBase-input": {
                          color: "#fff !important",
                        },
                        "& .MuiSvgIcon-root": {
                          color: "white",
                        },
                      }}
                    >
                      <MenuItem value={10} sx={{ color: "#fff" }}>
                        Products
                      </MenuItem>
                    </Select>
                  </FormControl>
                  <Box className={classes.selectText}>NFT Marketplace</Box>
                  <FormControl variant="standard" sx={{ m: 1, minWidth: 120 }}>
                    <Select
                      value={age}
                      onChange={handleChange}
                      MenuProps={{
                        disableScrollLock: true,
                      }}
                      sx={{
                        cursor: "pointer",

                        ".MuiOutlinedInput-notchedOutline": { border: 0 },
                        ".MuiOutlinedInput-input": {
                          border: 0,
                          padding: "0.5rem 2.3rem 0.5rem 1rem !important",
                        },
                        ".MuiInputBase-input": {
                          color: "#fff !important",
                        },
                        "& .MuiSvgIcon-root": {
                          color: "white",
                        },
                      }}
                    >
                      <MenuItem value={10} sx={{ color: "#fff" }}>
                        NFT Explore
                      </MenuItem>
                    </Select>
                  </FormControl>
                  <Box className={classes.selectText}>Web3 Store</Box>

                  <Box className={classes.selectText}>Developers</Box>

                  <FormControl variant="standard" sx={{ m: 1, minWidth: 120 }}>
                    <Select
                      value={age}
                      onChange={handleChange}
                      MenuProps={{
                        disableScrollLock: true,
                      }}
                      sx={{
                        ".MuiOutlinedInput-notchedOutline": { border: 0 },
                        ".MuiOutlinedInput-input": {
                          border: 0,
                          padding: "0.5rem 2.3rem 0.5rem 1rem !important",
                        },
                        ".MuiInputBase-input": {
                          color: "#fff !important",
                        },
                        "& .MuiSvgIcon-root": {
                          color: "white",
                        },
                      }}
                    >
                      <MenuItem value={10} sx={{ color: "#fff" }}>
                        Blog
                      </MenuItem>
                    </Select>
                  </FormControl>
                </Box>
              </Hidden>
            </Box>

            <Box display={matches ? "none" : "block"}>
              <Button
                variant="contained"
                sx={{
                  borderRadius: "8px",
                  color: "#EBEBEB",
                  fontFamily: "Poppins",
                  fontSize:elevenmatches?"12px": "14px",
                  background:
                    "linear-gradient(90deg, #5C1D94 0%, #7733A1 34.37%, #B12396 68.75%, #E30077 100%)",
                  paddingX: "2rem",
                  paddingY: "0.5rem",
                  "&.MuiButton-root": {
                    textTransform: "none",
                  },
                  "&.MuiButton-root:hover": {
                    background:
                      "linear-gradient(90deg, #5C1D94 0%, #7733A1 34.37%, #B12396 68.75%, #E30077 100%)",
                  },
                }}
                // className={classes.selectText}
              >
                Join Us
              </Button>
            </Box>

            <Hidden mdUp>
              {["left"].map((anchor) => (
                <React.Fragment key={anchor}>
                  <Button
                    onClick={toggleDrawer(anchor, true)}
                    style={{ zIndex: 1, justifyContent: "end", width: "100%" }}
                  >
                    <MenuIcon
                      style={{
                        fontSize: "38px",
                        cursor: "pointer",
                        color: "#fff",
                      }}
                    ></MenuIcon>
                  </Button>
                  <Paper style={{ background: "#120F1F" }}>
                    <SwipeableDrawer
                      classes={{ paper: classes.paper }}
                      anchor={anchor}
                      open={state[anchor]}
                      onClose={toggleDrawer(anchor, false)}
                      onOpen={toggleDrawer(anchor, true)}
                    >
                      {list(anchor)}
                    </SwipeableDrawer>
                  </Paper>
                </React.Fragment>
              ))}
            </Hidden>
          </Box>
        </Container>
      </Box>
    </Box>
  );
}

export default Navbar;
