import React from "react";
import Container from "@mui/material/Container";
import { Box, Divider, useMediaQuery } from "@mui/material";
import logo from "../../assets/Logo.png";
import twitter from "../../assets/twitter.png";
import linkdin from "../../assets/linkdin.png";
import youtube from "../../assets/youtube.png";
import github from "../../assets/github.png";
import telegram from "../../assets/telegram.png";
import useStyles from "./styles";
import divider from "../../assets/Divider.png";
export const Footer = () => {
  const classes = useStyles();
  const xxsmatches = useMediaQuery("(max-width:600px)");
  const xsmatches = useMediaQuery("(max-width:500px)");
  return (
    <Container maxWidth="xl">
      <Box className={classes.firstBox} mt={"4rem"}>
        <Box>
          <img src={logo} alt="" />
        </Box>
        <Box className={classes.mainbox} mr={xxsmatches ? 0 : 2}>
          <Box>
            <img src={twitter} alt="" />
          </Box>
          <Box>
            <img src={linkdin} alt="" />
          </Box>
          <Box>
            <img src={youtube} alt="" />
          </Box>
          <Box>
            <img src={github} alt="" />
          </Box>
          <Box>
            <img src={telegram} alt="" />
          </Box>
        </Box>
      </Box>
      <Box>
        <img src={divider} alt="" srcset="" width={"100%"} />
      </Box>
      <Box className={classes.stylinglastDiv} my={xxsmatches?1:2}>
        <Box className={classes.lastText}>
          © 2023 web3indexer • All Rights Reserved
        </Box>
      </Box>
    </Container>
  );
};

