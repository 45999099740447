import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  selectText: {
    color: "#EBEBEB",
    fontFamily: "Poppins",
    fontSize: "14px",
    cursor: "pointer",
  },
  list: {
    width: 250,
  },
  fullList: {
    width: "auto",
    alignItems: "center",
    gap: "1rem",
    display: "flex",
  },
  gridResponsive: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "start",
    "@media (max-width: 1200px)": {
      alignItems: "center",
      textAlign: "center",
    },
    "@media (max-width: 600px)": {
      alignItems: "start",
      textAlign: "start",
    },
  },
  subheading: {
    fontFamily: "Poppins",
    fontWeight: 700,
    fontSize: "16px",
    display: "flex",
    alignItems: "center",
    gap: "0.3rem",
    "@media (max-width: 600px)": {
      fontWeight: 700,
      fontSize: "16px",
    },
  },
  heading: {
    fontFamily: "Poppins",
    fontWeight: 700,
    fontSize: "50px",
    lineHeight: "70px",
    background:
      "linear-gradient(90deg, #5C1D94 0%, #7733A1 34.37%, #B12396 68.75%, #E30077 100%)",
    WebkitBackgroundClip: "text",
    WebkitTextFillColor: "transparent",
    "@media (max-width: 1100px)": {
      fontWeight: 700,
      fontSize: "59px",
    },
    "@media (max-width: 700px)": {
      fontWeight: 700,
      lineHeight: "60px",
      fontSize: "50px",
    },
    "@media (max-width: 600px)": {
      fontWeight: 700,
      lineHeight: "60px",
      fontSize: "48px",
    },
    "@media (max-width: 530px)": {
      fontWeight: 700,
      lineHeight: "60px",
      fontSize: "42px",
    },
    "@media (max-width: 500px)": {
      fontWeight: 600,
      lineHeight: "50px",
      fontSize: "33px",
    },
    "@media (max-width: 400px)": {
      fontSize: "24px",
      lineHeight: "30px",
    },
    "@media (max-width: 300px)": {
      fontSize: "21px",
      lineHeight: "26px",
    },
  },
  littleText: {
    background:
      "linear-gradient(90deg, #fff 0%, #fff 34.37%, #fff 68.75%, #fff 100%)",
    WebkitBackgroundClip: "text",
    WebkitTextFillColor: "transparent",
    fontWeight: 700,
    fontSize: "50px",
    marginLeft: "0.5rem",
    "@media (max-width: 1100px)": {
      fontWeight: 700,
      fontSize: "59px",
    },
    "@media (max-width: 700px)": {
      fontWeight: 700,
      lineHeight: "60px",
      fontSize: "50px",
    },
    "@media (max-width: 600px)": {
      fontWeight: 700,
      fontSize: "40px",
    },
    "@media (max-width: 500px)": {
      fontWeight: 600,
      fontSize: "33px",
    },
    "@media (max-width: 400px)": {
      fontSize: "24px",
    },
    "@media (max-width: 300px)": {
      fontSize: "21px",
    },
  },
  content: {
    fontFamily: "Poppins",
    fontWeight: 400,
    fontSize: "16px",
    color: "#EBEBEB",
    "@media (max-width: 600px)": {
      fontWeight: 500,
      fontSize: "14px",
    },
    "@media (max-width: 500px)": {
      fontWeight: 400,
      fontSize: "12px",
    },
    "@media (max-width: 400px)": {
      fontWeight: 400,
      fontSize: "10px",
    },
  },
  listedText: {
    fontFamily: "Poppins",
    fontWeight: 700,
    fontSize: "40px",
    color: "#fff",
    cursor: "pointer",
    "@media (max-width: 600px)": {
      fontWeight: 700,
      fontSize: "30px",
    },
    "@media (max-width: 400px)": {
      fontWeight: 400,
      fontSize: "20px",
    },
  },
  divider: {
    width: "0.1rem",
    height: "4.8rem",
    backgroundColor: "rgba(235, 235, 235, 1)",
  },
  dividerBox: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    gap: "2rem",
    "@media (max-width: 600px)": {
      gap: "3.3rem",
    },
    "@media (max-width: 460px)": {
      gap: "1.8rem",
    },
  },
  dividerMainBox: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    gap: "2.7rem",
    "@media (max-width: 600px)": {
    
      width: "100%",
      display: "flex",
      justifyContent: "space-between",
    },

    "@media (max-width: 400px)": {
      gap: "1.3rem",
    },
  },
  imgBox: {
    background: "rgba(255, 255, 255, 0.1)",
    border: "2px solid #1E50FF",
    backdropFilter: "blur(40px)",
    borderRadius: "8px",
    display: "flex",
    justifyContent: "center",
    flexDirection: "column",
    alignItems: "center",
    "@media (max-width: 600px)": {
      backdropFilter: "url()",
    },
  },
  imgInner: {
    display: "flex",
    justifyContent: "center",
    position: "absolute",
    top: "-15rem",
    "@media (max-width: 400px)": {
      position: "absolute",
      top: "-12rem",
    },
    "@media (max-width: 300px)": {
      position: "absolute",
      top: "-10rem",
    },
  },
  gridimg: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "end",
  },
  buildingButton: {
    backgroundColor: "#B12396",
    fontFamily: "Poppins",
    fontWeight: 600,
    fontSize: "16px",
  },
  buttonBox: {
    display: "flex",
    flexDirection: "row",
    gap: "1rem",
    marginTop: "6rem",
    "@media (max-width: 600px)": {
      display: "flex",
      flexDirection: "row",
 
    },
    "@media (max-width: 400px)": {
      marginTop: "4rem",
      flexDirection: "column",
    },
  },
  responsiveimg: {
    "@media (max-width: 400px)": {
      width: "80%",
    },
  },
}));

export default useStyles;
