import { Box, Dialog } from "@mui/material";
import React from "react";
import web3intro from "../../assets/web3indexerintro.mp4";
import { ControlBar, Player } from "video-react";
import poster from "../../assets/poster.png";

const CustomDialouge = ({ onClose, open }) => {
  return (
    <Dialog
      onClose={onClose}
      open={open}
      sx={{
        borderRadius: "25px",
        "& .MuiPaper-root": { borderRadius: "25px" },
        "& .MuiBackdrop-root": {
          backgroundColor: "transparent",
          backdropFilter: "blur(3px)",
        
        },
      }}
    >
      <Player src={web3intro} poster={poster}>
        <ControlBar autoHide={false} className="my-class" />
      </Player>
    </Dialog>
  );
};

export default CustomDialouge;
