import React from "react";
import { Box, useMediaQuery, Grid, LinearProgress } from "@mui/material";
import { InView, useInView } from "react-intersection-observer";
import logo from "../../assets/Logo2.png";
import forwardIcon from "../../assets/forwardIcon.png";
import rectangle from "../../assets/rectangle.png";
import circle from "../../assets/circle.png";
import triangle from "../../assets/triangle.png";
import useStyles from "./styles";
import CountUp from "react-countup";
const SliderComponent = () => {
  const classes = useStyles();
  const sevenmatches = useMediaQuery("(max-width:700px)");
  const sixmatches = useMediaQuery("(max-width:600px)");
  const fivematches = useMediaQuery("(max-width:500px)");
  const fourmatches = useMediaQuery("(max-width:400px)");
  const { ref, inView } = useInView({
    triggerOnce: true,
    rootMargin: "-100px 0px",
  });

  return (
    <InView as="div">
      <Grid container spacing={0} gap={"2rem"}>
        <Grid xs={12} sm={12} md={12} lg={7}>
          <Box className={classes.cardHeading}>
            The fastest
            <br /> Web3 APIs around
          </Box>
          <Box
            my={fourmatches ? "1rem" : "2rem "}
            maxWidth={
              fourmatches
                ? "8rem"
                : fivematches
                  ? "16rem"
                  : sixmatches
                    ? "18rem"
                    : sevenmatches
                      ? "20rem"
                      : "23rem"
            }
          >
            <Box className={classes.cardrow}>
              <img
                src={logo}
                alt=""
                srcset=""
                width={sixmatches ? "45%" : "auto"}
                style={{ marginBottom: fourmatches ? "0rem" : "1rem" }}
              />
              <Box
                display="flex"
                flexDirection={"column"}
                alignItems={sixmatches ? "center" : "end"}
              >
                <Box className={classes.cardsec}>
                  0.5s
                  
                </Box>
                <Box className={classes.cardsecContent}>Response Time</Box>
              </Box>
            </Box>
            <LinearProgress
              ref={ref}
              classes={{
                bar1Indeterminate: inView && classes.bar1Indeterminate,
                bar2Indeterminate: classes.bar2Indeterminate,
              }}
              variant="indeterminate"
              sx={{
                ".MuiLinearProgress-barColorPrimary": {
                  background: inView
                    ? "linear-gradient(90deg, #5C1D94 0%, #7733A1 34.37%, #B12396 68.75%, #E30077 100%)!important"
                    : "transparent",
                },
                "&.MuiLinearProgress-root": {
                  backgroundColor: "transparent !important",
                },
                height: fourmatches ? "0.3rem" : "0.4rem",
                borderRadius: "25px",

                marginBottom: "0.4rem",
              }}
            // value={60}
            />
          </Box>

          {/* {//* Second Box */}

          <Box
            my={fourmatches ? "1rem" : "2rem "}
            maxWidth={
              fourmatches
                ? "10rem"
                : fivematches
                  ? "18rem"
                  : sixmatches
                    ? "21rem"
                    : sevenmatches
                      ? "24rem"
                      : "28rem"
            }
          >
            <Box className={classes.cardrow2}>
              <Box className={classes.competiABox}>
                <Box>
                  <img src={rectangle} alt="" srcset="" />
                </Box>

                <Box className={classes.competitiorA}>Competitor A</Box>
              </Box>
              <Box>
                <Box className={classes.cardsec}>
                  0.92s
                  
                </Box>
              </Box>
            </Box>
            <LinearProgress
              ref={ref}
              classes={{
                bar1Indeterminate: inView && classes.bar1Indeterminate,
                bar2Indeterminate: classes.bar2Indeterminate,
              }}
              variant="indeterminate"
              sx={{
                ".MuiLinearProgress-barColorPrimary": {
                  background: inView
                    ? "linear-gradient(90deg, #5C1D94 0%, #7733A1 34.37%, #B12396 68.75%, #E30077 100%)!important"
                    : "transparent",
                },
                "&.MuiLinearProgress-root": {
                  backgroundColor: "transparent !important",
                },
                height: fourmatches ? "0.3rem" : "0.4rem",
                borderRadius: "25px",
                marginTop: "0.6rem",
                marginBottom: "0.4rem",
              }}
              value={100}
            />
          </Box>
          {/* //*Third Box */}
          <Box
            my={fourmatches ? "1rem" : "2rem "}
            maxWidth={
              fourmatches
                ? "12rem"
                : fivematches
                  ? "19rem"
                  : sixmatches
                    ? "23rem"
                    : sevenmatches
                      ? "28rem"
                      : "31rem"
            }
          >
            <Box className={classes.cardrow3}>
              <Box className={classes.competiABox}>
                <Box>
                  <img src={circle} alt="" srcset="" />
                </Box>
                <Box className={classes.competitiorA}>Competitor B</Box>
              </Box>
              <Box>
                <Box className={classes.cardsec}>
                  1.1s
                  
                </Box>
              </Box>
            </Box>
            <LinearProgress
              ref={ref}
              classes={{
                bar1Indeterminate: inView && classes.bar1Indeterminate,
                bar2Indeterminate: classes.bar2Indeterminate,
              }}
              variant="indeterminate"
              sx={{
                ".MuiLinearProgress-barColorPrimary": {
                  background: inView
                    ? "linear-gradient(90deg, #5C1D94 0%, #7733A1 34.37%, #B12396 68.75%, #E30077 100%)!important"
                    : "transparent",
                },
                "&.MuiLinearProgress-root": {
                  backgroundColor: "transparent !important",
                },
                height: fourmatches ? "0.3rem" : "0.4rem",
                borderRadius: "25px",
                marginTop: "0.6rem",
                marginBottom: "0.4rem",
              }}
              value={100}
            />
          </Box>
          {/* //* Fourth Box */}
          <Box
            maxWidth={
              fivematches
                ? "23rem"
                : sixmatches
                  ? "28rem"
                  : sevenmatches
                    ? "32rem"
                    : "37rem"
            }
          >
            <Box className={classes.cardrow4}>
              <Box className={classes.competiABox}>
                <Box>
                  <img src={triangle} alt="" srcset="" />
                </Box>
                <Box className={classes.competitiorA}>Competitor C</Box>
              </Box>
              <Box>
                <Box className={classes.cardsec}>
                  1.55s
                </Box>
              </Box>
            </Box>
            <LinearProgress
              ref={ref}
              classes={{
                bar1Indeterminate: inView && classes.bar1Indeterminate,
                bar2Indeterminate: classes.bar2Indeterminate,
              }}
              variant="indeterminate"
              sx={{
                ".MuiLinearProgress-barColorPrimary": {
                  background: inView
                    ? "linear-gradient(90deg, #5C1D94 0%, #7733A1 34.37%, #B12396 68.75%, #E30077 100%)!important"
                    : "transparent",
                },
                "&.MuiLinearProgress-root": {
                  backgroundColor: "transparent !important",
                },
                height: fourmatches ? "0.3rem" : "0.4rem",
                borderRadius: "25px",
                marginTop: "0.6rem",
                marginBottom: "0.4rem",
              }}
              value={10}
            />
          </Box>
        </Grid>
        <Grid
          xs={12}
          sm={12}
          md={12}
          lg={4}
          className={classes.stylingGrid}
          flexDirection={"column"}
        >
          <Box className={classes.text}>
            Revolutionary API response times, unparalleled reliability, and
            meticulously structured data.
          </Box>
          <Box className={classes.littlText} my={"0.7rem"}>
            <img src={forwardIcon} alt="" srcset="" />
            Try it now
          </Box>
        </Grid>
      </Grid>
    </InView>
  );
};

export default SliderComponent;
