import {
  Box,
  Button,
  useMediaQuery,
  Container,

} from "@mui/material";
import React, { useEffect,  useRef } from "react";
import bg from "../../assets/bg.png";
import useStyles from "./styles";

import blueAnimation from "../../blueAnimation.webm";
import { BlockChainData } from "../BlockChainData/BlockChainData";
import img1 from "../../assets/img1.png";
import img2 from "../../assets/img2.png";
import icon from "../../assets/icon.png";
import slider from "../../assets/slider.png";

import ReactCompareImage from "react-compare-image";
import SliderComponent from "./SliderComponent";
export const RoadMap = () => {
  const classes = useStyles();
  const largematches = useMediaQuery("(min-width:1700px)");
  const matches = useMediaQuery("(max-width:1200px)");

  const sixmatches = useMediaQuery("(max-width:600px)");

  const sxmatches = useMediaQuery("(max-width:900px)");
  const sxxmatches = useMediaQuery("(max-width:667px)");
  const fivematches = useMediaQuery("(max-width:500px)");
  const fourmatches = useMediaQuery("(max-width:440px)");
  const lessfourmatches = useMediaQuery("(max-width:400px)");
  const twomatches = useMediaQuery("(max-width:330px)");
  const ref = useRef(null);
  const loop = () => {
    ref.current.play();
  };

  useEffect(() => {
    loop(); //when  foucsed then loop
  }, [ref]);
  return (
    <>
      <Box
        sx={{
          background: sxxmatches ? "" : `url(${bg})`,
          backgroundPosition: "top -5rem center",
          backgroundRepeat: "no-repeat",
          backgroundSize: largematches ? "cover" : "cover",
          width: "100%",
        }}
      >
        <Container maxWidth="lg">
          <Box className={classes.buttonfeatured}>
            <Button
              variant="contained"
              sx={{
                "&.MuiButton-root": {
                  color: "#fff !important",
                  fontFamily: "Poppins",
                  fontWeight: 600,
                  fontSize: matches ? "12px" : "16px",
                  background:
                    "linear-gradient(90deg, #5C1D94 0%, #7733A1 34.37%, #B12396 68.75%, #E30077 100%)",
                  borderRadius: "8px",
                  paddingY: "0.8rem",
                  paddingX: twomatches
                    ? "2.1rem"
                    : lessfourmatches
                    ? "3.1rem"
                    : fourmatches
                    ? "3.7rem"
                    : fivematches
                    ? "4.6rem"
                    : sxxmatches
                    ? "6.5rem"
                    : sxmatches
                    ? "10.7rem"
                    : matches
                    ? "15rem"
                    : "3rem",
                },
              }}
            >
              Get Started
            </Button>
          </Box>

          <Box mt={4}>
            {sixmatches ? <Box>
              <Box><img src={img1} alt="" srcset="" width={"100%"}/></Box>
              <Box><img src={img2} alt="" srcset="" width={"100%"}/></Box>
            </Box> : <ReactCompareImage
              leftImage={img1}
              rightImage={img2}
              sliderLineColor="transparent"
              vertical={sixmatches ? true : false}
              handle={
                <>
                  {sixmatches ? (
                    <Box>
                      <img src={icon} alt="" srcset="" />
                    </Box>
                  ) : (
                    <div
                      style={{
                        height: "100%",
                      }}
                    >
                      {" "}
                      <img src={slider} alt="" srcset="" />
                    </div>
                  )}
                </>
              }
            />
            }
         
          </Box>
          <Box className={classes.card} mt={sixmatches ? "2rem" : "0rem"}>
            <SliderComponent />
          </Box>
        </Container>
        <Box className={classes.imgAdjust}>
          <video
            id="video"
            ref={ref}
            className={classes.vedioresponsive}
            autoPlay
            muted={true}
            loop
            src={blueAnimation}
          ></video>
        </Box>
      </Box>
      <BlockChainData />
    </>
  );
};
