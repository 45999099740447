import React from "react";
import useStyles from "./styles";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import { Box, Button, useMediaQuery } from "@mui/material";
import user from "../../assets/User.png";
import { Network } from "../Network/Network";
export const Community = () => {
  const classes = useStyles();
  const matches = useMediaQuery("(max-width:600px)");
  return (
    <>
      <Container
        sx={{
          "&.MuiContainer-root": {
            maxWidth: "1290px",
          },
        }}
      >
        <Grid container spacing={0} mt={matches ? "3rem" : "6rem"}>
          <Grid
            item
            xs={12}
            sm={12}
            md={12}
            lg={7}
            className={classes.gridone}
            flexDirection="column"
          >
            <Box className={classes.topText}>Community</Box>
            <Box className={classes.heading}>
              Join Our Community and Get Many Benefits
            </Box>
            <Box className={classes.content}>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
              eiusmod tempor incididunt ut labore et dolore magna aliqua.
            </Box>
            <Box className={classes.btnreponsiveness}>
              <Button
                variant="contained"
                sx={{
                  borderRadius: "8px",
                  color: "#EBEBEB",
                  fontFamily: "Poppins",
                  fontSize: "14px",
                  background:
                    "linear-gradient(90deg, #5C1D94 0%, #7733A1 34.37%, #B12396 68.75%, #E30077 100%)",
                  paddingX: "2rem",
                  paddingY: "0.7rem",
                  "&.MuiButton-root": {
                    textTransform: "none",
                  },
                  "&.MuiButton-root:hover": {
                    backgroundColor: "#1E50FF",
                  },
                }}
                className={classes.selectText}
              >
                Join Our Discord
              </Button>
            </Box>
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={5}>
            <img src={user} alt="" srcset="" width={"100%"} />
          </Grid>
        </Grid>
      </Container>
      <Network />
    </>
  );
};
