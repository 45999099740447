import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  topText: {
    fontFamily: "Poppins",

    fontWeight: 700,
    fontSize: "16px",
    textAlign: "center",
    color: "#C138BB",
    marginTop: "7rem",
    "@media (max-width: 600px)": {
      marginTop: "2rem",
    },
  },
  heading: {
    fontFamily: "Poppins",
    fontWeight: 700,
    fontSize: " 40px",
    textAlign: "center",
    marginTop: "2rem",
    marginBottom: "2rem",
    color: "#FFFFFF",
    "@media (max-width: 680px)": {
      fontSize: " 26px",
      marginTop: "1rem",
      fontWeight: 700,
    },
  },
  imgBox: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexWrap: " wrap",
    columnGap: "6rem",
    rowGap: "1rem",
    "@media (max-width: 680px)": {
      flexWrap: "wrap",
    },
    "@media (max-width: 400px)": {
   flexDirection:"column"

    },
  },
  imgBox2: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-evenly",
    "@media (max-width: 680px)": {
      justifyContent: "space-evenly",
      flexWrap: "wrap",
    },
  },
}));
export default useStyles;
