import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  topText: {
    fontFamily: "Poppins",

    fontWeight: 700,
    fontSize: "16px",

    color: "#F3347D",
  },
  btnreponsiveness: {
    display: "flex",

    "@media (max-width: 600px)": {
      display: "flex",
      alignSelf: "center",
    },
  },
  heading: {
    fontFamily: "Poppins",
    fontWeight: 700,
    fontSize: "56px",
    lineheight: "72px",
    color: "#FFFFFF",
    "@media (max-width: 600px)": {
      fontWeight: 700,
      fontSize: "32px",
      lineheight: "42px",
    },
    "@media (max-width: 400px)": {
      fontWeight: 500,
      fontSize: "30px",
      lineheight: "32px",
    },
  },
  content: {
    fontFamily: "Poppins",
    color: "#FFFFFF",
    fontWeight: 400,
    fontSize: "16px",
    "@media (max-width: 600px)": {
      fontSize: "14px",
    },
  },
  gridone: {
    display: "flex",
    flexDirection: "column",
    gap: "1.6rem",
    "@media (max-width: 1200px)": {
      justifyContent: "center",
      alignItems: "center",
      textAlign: "center",
    },
    "@media (max-width: 600px)": {
      justifyContent: "start",
      alignItems: "start",
      textAlign: "start",
    },
  },
}));

export default useStyles;
