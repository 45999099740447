import React, { useEffect, useRef, useState } from "react";
import Navbar from "./Navbar";
import {
  Box,
  Button,
  Container,
  Divider,
  Grid,
  useMediaQuery,
} from "@mui/material";
import theme from "../../utils/theme";
import useStyles from "./styles";
import forwardIcon from "../../assets/forwardIcon.png";
import PlayArrowIcon from "@mui/icons-material/PlayArrow";
import FeaturedSection from "../FeaturedSection/FeaturedSection";
import heroSectionAnimation from "../../heroSectionAnimation.webm";
import heromain from "../../assets/heromain.png";
import { TypeAnimation } from "react-type-animation";
import CustomDialouge from "./CustomDialouge";

const HeroSection = () => {
  const twelvesmatches = useMediaQuery("(max-width:1200px)");
  const ninesmatches = useMediaQuery("(max-width:900px)");
  const sixmatches = useMediaQuery("(max-width:600px)");
  const fivematches = useMediaQuery("(max-width:500px)");
  const llastsmatches = useMediaQuery("(max-width:400px)");
  const smatches = useMediaQuery("(max-width:300px)");
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = (value) => {
    setOpen(false);
    
  };  
  const ref = useRef(null);
  const loop = () => {
    ref.current.play();
  };

  useEffect(() => {
    loop();
  }, []);

  return (
    <>
      <Box
        sx={{
          background: ninesmatches ? "" : `url(${heromain})`,
          backgroundSize: "contain",
          backgroundRepeat: "no-repeat",
          position: "absolute",
          zIndex: "-1",
          width: "100%",
        }}
        pb={3}
      >
        <Navbar />
        <Container
          maxWidth="lg"
          sx={{
            "&.MuiContainer-root": {
              maxWidth: "1303px",
            },
          }}
        >
          <Grid container spacing={0} mt={sixmatches ? 4 : 7}>
            <Grid
              item
              xs={12}
              sm={12}
              md={12}
              lg={6}
              className={classes.gridResponsive}
              flexDirection={"column"}
              mt={sixmatches ? "0rem" : ninesmatches ? "1rem" : "1.7rem"}
            >
              <Box
                color={theme.palette.primary.light}
                className={classes.subheading}
              >
                Apply for Development Grant
                <img src={forwardIcon} alt="icon" />
              </Box>

              <Box className={classes.heading} mt={2} mb={1}>
                Fastest, Reliable & Instant
                <TypeAnimation
                  sequence={[
                    "Web3 API",
                    2000,
                
                    "Web3 Explorer",
                    2000,

                    () => {
                      console.log("Sequence completed");
                    },
                  ]}
                 
                  cursor={true}
                  repeat={Infinity}
                  className={classes.littleText}
                />
               
              </Box>
              <Box className={classes.content}>
                Enterprise-grade APIs and real-time blockchain data that make
                Web3 development as easy as Web2.
              </Box>
              <Box className={classes.dividerMainBox} mt={sixmatches ? 5 : 7}>
                <Box className={classes.dividerBox}>
                  <Box className={classes.listedText}>
                    250M <Box className={classes.content}>Labeled Items</Box>
                  </Box>

                  <Divider className={classes.divider} />
                </Box>
                <Box className={classes.dividerBox}>
                  <Box className={classes.listedText}>
                    95% <Box className={classes.content}>All Chain TVL</Box>
                  </Box>

                  <Divider className={classes.divider} />
                </Box>
                <Box>
                  <Box className={classes.listedText}>
                    500TB <Box className={classes.content}>Processed Daily</Box>
                  </Box>
                </Box>
              </Box>
            </Grid>
            <Grid
              item
              xs={12}
              sm={12}
              md={12}
              lg={5.8}
              className={classes.gridimg}
              display={"flex"}
              flexDirection={"column"}
              justifyContent={"end"}
              alignItems={twelvesmatches ? "" : "end"}
              mt={twelvesmatches ? "3rem" : ""}
            >
              <Box
                className={classes.imgBox}
                pb={4}
                pt={3.7}
                mt={llastsmatches ? "9rem" : twelvesmatches ? "13rem" : ""}
                px={twelvesmatches ? "" : "3rem"}
              >
                <Box className={classes.imgInner}>
                  <video
                    id="video"
                    ref={ref}
                    style={{
                      width: smatches
                        ? "300px"
                        : llastsmatches
                          ? "320px"
                          : "400px",
                    }}
                    autoPlay
                    loop
                    muted={true}
                    src={heroSectionAnimation}
                  ></video>
                </Box>
                <Box className={classes.buttonBox}>
                  <Button
                    variant="contained"
                    sx={{
                      "&.MuiButton-root": {
                        color: "#fff !important",
                        fontFamily: "Poppins",
                        fontWeight: sixmatches ? 500 : 600,
                        fontSize: fivematches
                          ? "12px"
                          : sixmatches
                            ? "14px"
                            : "16px",
                        background:
                          "linear-gradient(90deg, #5C1D94 0%, #7733A1 34.37%, #B12396 68.75%, #E30077 100%)",
                        borderRadius: "8px",
                        padding: fivematches
                          ? "0.6rem 2.3rem"
                          : "0.5rem 2.3rem",
                      },
                    }}
                  >
                    Start Building
                  </Button>
                  <Button
                    variant="outlined"
                    sx={{
                      "&.MuiButton-root": {
                        color: "#fff !important",
                        fontFamily: "Poppins",
                        fontWeight: sixmatches ? 500 : 600,
                        fontSize: fivematches
                          ? "12px"
                          : sixmatches
                            ? "14px"
                            : "16px",
                        border: "1px solid #FFFFFF",
                        borderRadius: "8px",
                        padding: fivematches ? "0.6rem 2rem" : "0.5rem 2rem",
                      },
                    }}
                    onClick={handleClickOpen}
                    endIcon={<PlayArrowIcon />}
                  >
                    How we work
                  </Button>
                </Box>
              </Box>
            </Grid>
          </Grid>
          <CustomDialouge    open={open}
        onClose={handleClose}/>
        </Container>
        <FeaturedSection />
      </Box>
    </>
  );
};

export default HeroSection;
