import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  bar1Indeterminate: {
    width: "auto",
    animation: "$indeterminate1 2s linear forwards !important",
  },
  bar2Indeterminate: {
    display: "none",
  },
  "@keyframes indeterminate1": {
    "0%": {
      left: "-15%",
      right: "100%",
    },
    "100%": {
      left: "0%",
      right: "0%",
    },
  },
  heading: {
    fontFamily: "Poppins",
    fontWeight: 600,
    fontSize: "40px",
    color: "#ffff",
    "@media (max-width: 600px)": {
      fontWeight: 600,
      fontSize: "35px",
      textAlign: "start",
    },
  },
  subHeading: {
    fontFamily: "Poppins",
    fontWeight: 400,
    fontSize: "16px",
    color: "#EBEBEB",
    "@media (max-width: 600px)": {
      fontWeight: 400,
      fontSize: "14px",
    },
  },
  linearProgress: {
    // height: "0.1px",
    borderRadius: "25px",
    marginBottom: "0.4rem",
    maxWidth: "23rem",
  },
  numbring: {
    fontFamily: "Poppins",
    fontWeight: 600,
    fontSize: "48px",
    color: "#EBEBEB",
    "@media (max-width: 1200px)": {
      fontWeight: 600,
      fontSize: "36px",
    },
  },
  content: {
    fontWeight: 400,
    fontSize: "16px",
    color: "#EBEBEB",
    "@media (max-width: 1200px)": {
      fontSize: "16px",
    },
  },
  rowStyling: {
    maxWidth: "23rem",
    display: "flex",
    flexDirection: "column",
    "@media (max-width: 1200px)": {
      width: "23rem",
    },
    "@media (max-width: 600px)": {
      width: "100%",
      display: "block",
    },
  },
  data: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    "@media (max-width: 600px)": {
      gap: "6rem",
      justifyContent: "start",
    },
  },
  data2: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    
    "@media (max-width: 600px)": {
      gap: "6rem",
      justifyContent: "start",
    },
  },
  gridresponsiveness: {
    display: "flex",
    flexDirection: "column",
    gap: "1rem",
    "@media (max-width: 1200px)": {
      alignItems: "center",
      textAlign: "center",
      gap: "1.5rem",
    },
    "@media (max-width: 600px)": {
      alignItems: "start",
      textAlign: "start",
      gap: "1.5rem",
    },
  },
  secondgrid: {
    display: "flex",
    justifyContent: "end",
  },
}));

export default useStyles;
