import React from "react";
import Container from "@mui/material/Container";
import { Box } from "@material-ui/core";
import useStyles from "./styles";
import ava from "../../assets/ava.png";
import pol from "../../assets/poly.png";
import bin from "../../assets/bin.png";
import kl from "../../assets/kl.png";
import cr from "../../assets/cr.png";
import vl from "../../assets/vl.png";
import sol from "../../assets/sol.png";
import fus from "../../assets/fus.png";
import { Stack, Grid, useMediaQuery } from "@mui/material";
import line from "../../assets/line.png";
import { NewsLetter } from "../NewsLetter/NewsLetter";
export const Network = () => {
  const classes = useStyles();
  const ninesmatches = useMediaQuery("(max-width:900px)");
  const sixsmatches = useMediaQuery("(max-width:600px)");
  const foursmatches = useMediaQuery("(max-width:400px)");

  return (
    <>
      <Container maxWidth="md">
        <Box>
          <Box className={classes.topText}>NETWORK</Box>
          <Box className={classes.heading}>Blockchain Use</Box>
          <Stack spacing={3} direction="column">
            <Grid
              container
              spacing={0}
              display="flex"
              flexDirection={foursmatches ? "column" : "row"}
              alignItems={foursmatches ? "center" : "flex-start"}
              justifyContent={"center"}
            >
              <Grid
                item
                xs={3.5}
                sm={4}
                md={12}
                lg={12}
                xl={12}
                display="flex"
                justifyContent={ninesmatches ? "center" : "space-around"}
                flexDirection={ninesmatches ? "column" : "row"}
                alignItems={ninesmatches ? "center" : ""}
                gap={ninesmatches ? "1rem" : ""}
              >
                <Box>
                  <img
                    src={ava}
                    alt=""
                    srcset=""
                    width={sixsmatches ? "80%" : "100%"}
                  />
                </Box>
                <Box>
                  <img
                    src={pol}
                    alt=""
                    srcset=""
                    width={sixsmatches ? "80%" : "100%"}
                  />
                </Box>
                <Box>
                  <img
                    src={bin}
                    alt=""
                    srcset=""
                    width={sixsmatches ? "80%" : "100%"}
                  />
                </Box>
                <Box>
                  <img
                    src={kl}
                    alt=""
                    srcset=""
                    width={sixsmatches ? "80%" : "100%"}
                  />
                </Box>
              </Grid>
              <Grid
                ml={ninesmatches ? 0 : 2}
                item
                xs={3}
                sm={4}
                md={12}
                lg={12}
                xl={12}
                display="flex"
                justifyContent={ninesmatches ? "center" : "space-evenly"}
                flexDirection={ninesmatches ? "column" : "row"}
                alignItems={ninesmatches ? "center" : ""}
                gap={sixsmatches ? "1.6rem" : ninesmatches ? "1.8rem" : ""}
              >
                <Box>
                  <img
                    src={cr}
                    alt=""
                    srcset=""
                    width={sixsmatches ? "80%" : "100%"}
                  />
                </Box>
                <Box>
                  <img
                    src={vl}
                    alt=""
                    srcset=""
                    width={sixsmatches ? "80%" : "100%"}
                  />
                </Box>
                <Box>
                  <img
                    src={sol}
                    alt=""
                    srcset=""
                    width={sixsmatches ? "80%" : "100%"}
                  />
                </Box>
                <Box>
                  <img
                    src={fus}
                    alt=""
                    srcset=""
                    width={sixsmatches ? "80%" : "100%"}
                  />
                </Box>
              </Grid>
            </Grid>

            <Box>
              <img src={line} alt="" srcset="" width={"100%"} />
            </Box>
          </Stack>
        </Box>
      </Container>
      <NewsLetter />
    </>
  );
};
