import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  firstBox: {
    display: "flex",
    justifyContent: "space-between",
    "@media (max-width: 600px)": {
      flexDirection: "column",
      alignItems: "start",
      gap: "1rem",
    },
  },
  mainbox: {
    display: "flex",
    gap: "1rem",
  },
  divider: {
    height: "0.1rem",
    background:
      "linear-gradient(90deg, #5C1D94 0%, #7733A1 34.37%, #B12396 68.75%, #E30077 100%)",
  },
  stylinglastDiv: {
    display: "flex",
    justifyContent: "center"
    ,
  },
  lastText: {
    fontSize: "16px",
    fontWeight: 400,
    color: "#fff",
  },
}));
export default useStyles;
